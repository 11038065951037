<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-primary">10</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`Удалить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(code_cabinet)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-select v-if="variantAttribute"
                                       size="sm"
                                       class="w-200px"
                                       v-model="data.item.code_cabinet"
                                       text-field="name_ru"
                                       value-field="code"
                                       :options="dictCabinet"
                                       @change="checkDoubles(data.item, 'cabinet')"
                        >
                        </b-form-select>
                        <div v-else>{{ dictCabinet.find(el => el.code === data.value).name_ru }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Такая запись уже в базе существует</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(code_furniture)="data">
                    <b-form-select v-if="variantAttribute"
                                   size="sm"
                                   class="w-200px"
                                   v-model="data.item.code_furniture"
                                   text-field="name_ru"
                                   value-field="code_furniture"
                                   :options="data.item.dictFurniture"
                                   @change="checkDoubles(data.item, 'furniture')"
                    >
                    </b-form-select>
                    <div v-else>{{ data.item.dictFurniture.find(el => el.code_furniture === data.value).name_ru }}</div>
                </template>
                <template #cell(amount)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.amount"
                                  @change="v => data.item.amount = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'amount', data.item.amount, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(made_year)="data">
                    <div v-if="variantAttribute" :class="{ 'error': (data.item.made_year.toString().length !== 4 || parseInt(data.item.made_year) > header.cur_year)}">
                        <b-form-input class="text-right"
                                      :value="data.item.made_year"
                                      @change="v => data.item.made_year = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^[0-9]+$')"  
                                      @blur="inputFixedVldtn(data.item, 'made_year', data.item.made_year, 0)">
                        </b-form-input>
                        <i class="icon icon-danger" v-if="(data.item.made_year.toString().length !== 4 || parseInt(data.item.made_year) > header.cur_year)"></i>
                        <div class="pop-up" v-if="(data.item.made_year.toString().length !== 4 || parseInt(data.item.made_year) > header.cur_year)">
                            <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                            <p>Длина текста - не менее и не более 4-х символов</p>
                        </div>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(wear)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.wear"
                                  @change="v => data.item.wear = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'wear', data.item.wear, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(plan)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.plan"
                                  @change="v => data.item.plan = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'plan', data.item.plan, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(cost)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost"
                                  @change="v => data.item.cost = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'cost', data.item.cost, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>

                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItemWithAttachedFiles('Удалить запись?', data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="9">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import store from "../../services/store";
// import BudgetAttachFile from "./components/budget-attach-file";
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form02_414',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '02-414',
                name_ru: 'Расчет расходов государственных органов на приобретение офисной мебели',
                name_kk: 'Мемлекеттiк мекеменiң кеңсе жиһаздын сатып алуға арналған шығыстарын есептеу'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'code_cabinet',
                    label: 'Наименование должности'
                },
                {
                    key: 'code_furniture',
                    label: 'Наименование офисной мебели'
                },
                {
                    key: 'standard',
                    label: 'Норматив на приобретение офисной мебели'
                },
                {
                    key: 'amount',
                    label: 'Фактическое количество офисной мебели имеющиеся в наличии'
                },
                {
                    key: 'made_year',
                    label: 'Год выпуска'
                },
                {
                    key: 'wear',
                    label: 'Износ в соответствии с нормативами'
                },
                {
                    key: 'plan',
                    label: 'Количество офисной мебели, планируемое приобрести'
                },
                {
                    key: 'cost',
                    label: 'Стоимость за единицу'
                },
                {
                    key: 'total',
                    label: 'Общая стоимость (графа 7 х графа 8)'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dictCabinet: null,
            dictFurniture: null,
            cabinetFurniture: [],
            files: null,
            load: false,
            isLoad: false,
            openDisabled: false,
            usrId: null,
            budgetLevel: [],
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },

    async mounted() {
        this.usrId = await this.getUsrId();
        await this.loadBudgetLevel();
        await this.loadDictCabinet();
        await this.loadDictFurniture();
        await this.loadCabinetFurniture();
    },

    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },
        async getUsrId() {
            if (store.state.user.sub === undefined) {
                return null;
            }
            return store.state.user.sub;
        },

        addItem() {
            if (this.header !== null) {
                const item = { id: this.newRowStartId };
                this.newRowStartId--;
                this.itemUpdate(item);

                Object.defineProperty(item, 'total', {
                    get: function () {
                        const total = (item.cost * item.plan) / 1000;
                        return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                    }
                });
                this.budgetForm.push(item);
            }
            this.checkDoubles();
        },

        checkDoubles(value, str) {
            const firstEntryItm = {};
            this.budgetForm.forEach((itm, idx) => {
                const cabinetCode = itm.code_cabinet ? itm.code_cabinet : 'no code';   
                const furnitureCode = itm.code_furniture ? itm.code_furniture : 'no code'; 
                if (!firstEntryItm.hasOwnProperty(cabinetCode)) {
                    firstEntryItm[cabinetCode] = {};
                    firstEntryItm[cabinetCode][furnitureCode] = idx;
                    itm.checkForDuplicates = false;
                } else {
                    if (!firstEntryItm[cabinetCode].hasOwnProperty(furnitureCode)) {
                        firstEntryItm[cabinetCode][furnitureCode] = idx;
                        itm.checkForDuplicates = false;
                    } else {                        
                        itm.checkForDuplicates = true;
                        const firsDoubleIdx = firstEntryItm[cabinetCode][furnitureCode];
                        this.budgetForm[firsDoubleIdx].checkForDuplicates = true;
                    };
                };
            });
            if (value !== undefined) {
                if (str === 'cabinet') {
                    this.changeCabinet(value);
                } else if (str === 'furniture') {
                    this.changeFurniture(value);

                }
            }
        },

        afterDeleted() {
            this.checkDoubles();
        },

        changeCabinet(item) {
            this.$set(item, 'dictFurniture',
                this.cabinetFurniture.filter(row => row.code_cabinet === item.code_cabinet));
        },

        changeFurniture(item) {
            const values = this.cabinetFurniture.filter(row =>
                row.code_cabinet === item.code_cabinet && row.code_furniture === item.code_furniture);
            if (values !== undefined) {
                this.$set(item, 'standard', values[0].amount);
            }
        },

        downloadRep() {
            const that = this;
            Ax(
                {
                    url: '/api-py/budg_' + that.form.code.replace('-', '_') + '/' + JSON.stringify(that.header),
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма '+ that.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        async itemUpdate(item) {
            this.$set(item, 'code_cabinet', null);
            this.$set(item, 'code_furniture', null);
            this.$set(item, 'standard', 0);
            this.$set(item, 'amount', 0);
            this.$set(item, 'made_year', 0);
            this.$set(item, 'wear', 0);
            this.$set(item, 'plan', 0);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'cost', 0);

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.code === code.toString()) {
                    return item;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const charCode = event.keyCode;
            const key = String.fromCharCode(charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBudgetLevel() {
            this.budgetLevel.splice(0);
            if (this.usrId === null) { return; }
            let result = [];
            try {
                result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки уровня бюджета', error.toString());
                return;
            }

            for (const el of result) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const indx = el.budget_level.lastIndexOf('_');
                if (indx >= 0) {
                    this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
                }
            }
            if (this.budgetLevel.length === 0) {
                this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
            }
        },

        async loadCabinetFurniture(){
            try {
                const response = await fetch('/api-py/dictionary/cabinet_furniture/');
                const items = await response.json();

                for (const row of items) {
                    const furniture = this.getItem(row.code_furniture, this.dictFurniture);
                    if (furniture !== null) {
                        this.$set(row, 'name_ru', furniture.name_ru);
                    }
                }
                this.cabinetFurniture = items;
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCabinetFurniture', error.toString());
            }
        },

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();
                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicates равен true
                const listCheckDuplicates = [];

                await values.forEach(val => {
                    const item = val;
                    item['checkForDuplicates'] = !!listCheckDuplicates.find(el => el.code_cabinet === item.code_cabinet && el.code_furniture === item.code_furniture)

                    listCheckDuplicates.push({
                        'code_cabinet': item.code_cabinet,
                        'code_furniture': item.code_furniture
                    });
                    item.files = val.files;
                    item.row_files = val.row_files;
                    item.itemToDelete = false;

                    this.changeCabinet(item);
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const total = (item.cost * item.plan) / 1000;
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
            }
            this.load = false;
        },

        async loadDictCabinet() {
            try {
                const response = await fetch('/api-py/dictionary/cabinets/');
                this.dictCabinet = await response.json();
                this.dictCabinet = this.dictCabinet.filter(row => this.budgetLevel.includes(row.budget_level_id));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictCabinet', error.toString());
            }
        },

        async loadDictFurniture() {
            try {
                const response = await fetch('/api-py/dictionary/furniture/');
                this.dictFurniture = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictFurniture', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (!((row.code_cabinet.length === 0) || (row.code_furniture.length === 0)
                    || (row.standard.length === 0) || (row.made_year.toString().length !== 4) || (parseInt(row.made_year) > this.header.cur_year))) {
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'code_cabinet', row.code_cabinet);
                    this.$set(item, 'code_furniture', row.code_furniture);
                    this.$set(item, 'standard', parseInt(row.standard));
                    this.$set(item, 'amount', parseInt(row.amount));
                    this.$set(item, 'made_year', parseInt(row.made_year));
                    this.$set(item, 'wear', parseFloat(row.wear));
                    this.$set(item, 'plan', parseInt(row.plan));
                    this.$set(item, 'cost', parseFloat(row.cost));
                    this.$set(item, 'row_files', row.row_files);
                    this.$set(item, 'total', row.total);
                    values.push(item);
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                this.makeToast('danger', 'Предупреждение', 'Данные не соответствуют контролю. Данные не сохранены!');
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values, error);
                }
                else {
                    this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
                }
            }
        }, // подготовка к сохранению

        async save(values, error) {
            this.isLoad = true;
            try {
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },

    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
</style>